.suggestion_heading {
    text-align: center;
}

.suggestion_container {
    padding: 2em 5.9rem;
}


@media (max-width: 700px) {
    .suggestion_container {
    padding: 2em 0.8em;
    }
}