.products_page {
  background-image: url(../../images/products_banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 300px;

}

.shop_nav .customer_section {
  color: white;
}

.products_details {
  padding: 8em 12em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5em;
}

.products_details_left {
  border-right: 1px solid gray;
  margin: 0 0 0 3em;
  width: 55%;
  display: flex;
  gap: 2em;
}

.products_details_left img {
  width: 500px;
  border-radius: 10px;
}

.products_details_right {
  width: 45%;
}

.products_details_right h1 {
  margin-bottom: 1em;
  font-size: 2.6em;
}

.products_details_right p {
  font-size: 1.2em;
  margin-bottom: 1em;
}
.products_details_right .product_price {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 1em;
}

.product_shop_options {
  width: 100%;
  padding: 1em 0;
  font-size: 1.2em;
  font-weight: 600;
  color: rgb(20, 120, 170);
  margin: 1.4em 0;
}
.ratings {
  display: inline;
  text-decoration: underline;
}
.products_choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products_choice .products_choice_cart,
.products_choice_buy {
  padding: 1.1em 3.2em;
  font-size: 1.1em;
  font-weight: 600;
  border: none;
  background: white;
  border: 1px solid black;
  cursor: pointer;
}

.products_choice_buy {
  background: rgb(0, 0, 0);
  color: white;
}

.products_choice_cart:hover {
  background: #000;
  color: white;
  transition: 0.4s ease-in-out;
}

.products_choice_buy:hover {
  background: white;
  color: #000;
  transition: 0.4s ease-in-out;
}

.details_bar {
  color: white;
}


.fa-cart-shopping:hover {
  transform: scale(1.3);
  transition: 0.7s ;
}

@media (max-width: 1282px) {
  .shop_nav .customer_section {
    color: black;
  }
  
}

@media (max-width: 700px) {
  .products_page  {
    height: 150px;
  }

  .products_page_nav .products_page_brand {
    font-size: 1.5em;
  }

  .products_page_nav .navigation {
    display: none;
  }

  .products_page_nav .navigation_active {
    position: fixed;
    width: 100%;
    height:100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.5);

  }

 .products_page .shop_navigation-items {
    background: #ffff;
    width: 90%;
    max-width: 600px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    gap: 2.2em;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
    height: 63vh;
  }

  .products_page_nav .shop_navigation-items a {
    color: black;
  }

  .details_bar {
    color: black;
  }

  .products_details {
    padding: 3em 0em;
    flex-direction: column;
  }

  .products_details_left {
    flex-direction: column;
    border: none;
  }

  .products_details_left img {
    width: 300px;
    border-radius: 10px;
  }

  .products_details_right {
    width: 100%;
    padding: 0 1em;
  }

  .products_details_right h1 {
    margin-bottom: 1em;
    font-size: 1.6em;
  }

  .products_details_right p {
    z-index: 0;
  }
  .products_choice .products_choice_cart,
  .products_choice_buy {
    padding: 1.1em 2em;
  }

  .shop_nav .customer_section {
    color: black;
  }
}
