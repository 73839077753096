.products,
.products2 {
  padding: 40px 150px;
  background: #f6f6ee;
  margin: 8em 0;
}

.products2 {
  margin-top: -8em;
}

.products h2 {
  text-align: center;
  font-size: 2.7em;
  margin-bottom: 1em;
}

.main_card {
  width: inherit;
  width: 300px;
  margin-left: 25px;
  border: 1px solid gray;
  border-radius: 20px;
  padding: 1em;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.slick-track {
  display: flex;
}
.slick-slider {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.slick-slide {
  padding-right: 10px;
}

.slick-slide:last-child {
  padding-right: 0;
}

.slick-slide img {
  display: block;
}

.slick-list {
  margin: 0 15px;
}

.card_img {
  max-width: 268px;
  border-radius: 20px;
  cursor: pointer;
}

.card_img:hover {
  transform: scale(1.1);
  transition: 0.7s ease-in-out;
}
.product_name {
  font-size: 1.3em;
  margin: 0.7em 0;
}

.product_info {
  margin-bottom: 0.9em;
}

.product-actions {
  padding: 0 1em;
}

.product-actions .primary {
  background: #39a935;
  padding: 7px 20px;
  color: white;
  font-size: 1em;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.product-actions .primary:hover {
  /* hover effect on buy button */
}

.product-actions .secondary {
  background: #3e7fcf;
  padding: 7px 20px;
  color: white;
  font-size: 1em;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.slick-prev {
  width: 50px;
  left: 0;
  top: 40%;
  position: absolute;
  height: 50px;
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-next {
  width: 50px;
  right: 0;
  top: 40%;
  position: absolute;
  height: 50px;
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: tomato;
}
.product-actions {
  display: flex;
  justify-content: space-between;
}

.loading_text {
  text-align: center;
  font-size: 1.1rem;
}

@media (max-width: 1550px) {
  .products,
  .products2 {
    padding: 40px 130px;
  }

  .main_card {
    width: inherit;
    width: 263px;
    height: 440px;
  }
  .card_img {
    max-width: 230px;
  }
}

@media (max-width: 1440px) {
  .products,
  .products2 {
    padding: 40px 100px;
  }
}

@media (max-width: 1328px) {
  .products,
  .products2 {
    padding: 40px 50px;
  }
}

@media (max-width: 1026px) {
  .main_card {
    width: inherit;
    width: 230px;
  }
  .card_img {
    max-width: 200px;
  }
}

@media (max-width: 700px) {
  .products,
  .products2 {
    padding: 40px 5px;
    margin: 3em 0;
  }

  .products h2 {
    font-size: 1.8em;
  }

  .main_card {
    width: 300px;
    margin-left: 3em;
    height: 430px;
  }

  .card_img {
    max-width: 260px;
  }

  .product_name {
    font-size: 1em;
    margin: 0.7em 0;
  }
  .product_info {
    margin-bottom: 0.6em;
  }
}
