* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



header {
  z-index: 888;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.5s ease;
  border-bottom: 1px solid gray;
  user-select: none;
}

.address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: 10px;
  padding: 15px 200px;
  border-bottom: 1px solid gray;
}

.mail_phone {
  width: 53%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mail_phone_containers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}

.address i {
  color: #268f22;
}

.contact {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact_no {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.social {
  display: flex;
  gap: 7px;
}

.social i {
  color: white;
}

.second_nav {
  padding: 2em 12em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}

header .brand {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  /* text-transform: uppercase; */
  text-decoration: none;
}

header .navigation {
  position: relative;
}

.navigation-items {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5px;
}

header .navigation .navigation-items a {
  position: relative;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px;
  transition: 0.3s ease;
}

header .navigation .navigation-items a:before {
  content: "";
  position: absolute;
  background: #000;
  width: 0;
  height: 3px;
  bottom: -5px;
  left: 0;
}

header .navigation .navigation-items a:hover:before {
  width: 100%;
}

section {
  padding: 100px 200px;
}

.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #2696e9;
  user-select: none;
}

.home::before {
  z-index: 777;
  content: "";
  position: absolute;
  background: rgba(3, 96, 251, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.home .content {
  z-index: 888;
  color: #ffff;
  width: 70%;
  margin-top: 50px;
}

.home .content h1 {
  font-size: 4em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 75px;
  margin-bottom: 40px;
}

.home .content h1 span {
  font-size: 1.2em;
  font-weight: 600;
}

.home .content p {
  margin-bottom: 65px;
}

.home .content a {
  background: #fff;
  padding: 15px 35px;
  color: #1680ac;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  border-radius: 2px;
}

.home .media-icons {
  z-index: 888;
  position: absolute;
  right: 30px;
  display: flex;
  flex-direction: column;
  transition: 0.5s ease;
}

.home .media-icons a {
  color: #fff;
  font-size: 1.6em;
}

.home.media-icons a:not(:last-child) {
  margin-bottom: 20px;
}

.home .media-icons a:hover {
  transform: scale(1.3);
}

.home img {
  z-index: 000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-btn {
  display: none;
}

.slider-navigation {
  z-index: 888;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(80px);
  margin-bottom: 12px;
}

.slider-navigation .nav-btn {
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  transition: 0.3s ease;
}

.slider-navigation .nav-btn.active {
  background: #2696e9;
}

.slider-navigation .nav-btn:not(:last-child) {
  margin-right: 20px;
}

.slider-navigation .nav-btn:hover {
  transform: scale(1.2);
}

.image_slide {
  position: absolute;
  width: 100%;
  clip-path: circle(0% at 0 50%);
}

.image_slide.active {
  clip-path: circle(150% at 0 50%);
  transition: 2s ease;
  transition-property: clip-path;
}

.search_container {
  margin-left: 25px;
  border: 1px solid white;
  border-radius: 30px;
  position: relative;

}

.search_container .search_bar {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  padding: 0 10px;
  margin: 0 5px;
  font-size: 1.2rem;
  color: white;
  
}

.fa-magnifying-glass {
  color: white;  
  cursor: pointer;

}

.search_container .search_bar:focus {
  outline: none;
}

.search_bar::placeholder {
  color: white;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 1em 0;
  z-index: 1000;
  color: #000;
  /* height: 500px; */
}


.suggestion_item {
  /* height: 30px;   */
  cursor: pointer;
  color: #000;
  border-bottom: 1px solid gray;
  padding: 10px;
  /* font-size: 3em; */
}

.suggestion_item:hover {
  background-color: #f0f0f0;
}

.no_results {
  padding: 10px;
  color: #999;
}

.search_container i {
  font-size: 1.2rem;
  margin: 0 8px;
  cursor: pointer;
  border-left: 1px solid;
  padding: 10px;
}

.cusotmer_section {
  /* display: inline; */
  margin-left: 1.6em;
  position: relative;
  color: white;
}

.cusotmer_section .fa-solid,
.fa-regular {
  margin-left: 12px;
  font-size: 1.6em;
}

.cusotmer_section .cart_count {
  position: absolute;
  top: -21px;
  left: 31px;
  border: 1px solid gray;
  background: #47f741;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 11px;
}

.cusotmer_section .wish_count {
  position: absolute;
  top: -21px;
  left: 67px;
  border: 1px solid gray;
  background: #47f741;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 11px;
}

.cusotmer_section .fa-solid:hover,
.fa-regular:hover {
  transform: scale(1.3);
  transition: 0.7s;
}

/* cart section */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.cart {
  position: fixed;
  top: 0;
  right: -100%;
  width: 30%;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.4s ease;
  z-index: 1000;
  background: rgb(223, 202, 202);
}

.cart_open {
  right: 0;
}



@media (max-width: 1583px) {
  .address {
    padding: 15px 50px;
  }

  .second_nav {
    padding: 25px 50px;
  }
}

@media (max-width: 1282px) {
  .menu-btn {
    display: block;
  }

  header .navigation {
    display: none;
  }

  .search_container .search_bar {
    color: black;

  }
  .search_bar::placeholder {
    color: black;
  }

  .menu-btn {
    display: block;
    color: #ffff;
    cursor: pointer;
    z-index: 888;
  }

  header {
    z-index: 999;
    position: absolute;
  
  }
  
  .cusotmer_section i{
    color: black;
  }

  header .navigation_active {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.5);
  }

  header .navigation .navigation-items {
    background: #ffff;
    width: 90%;
    max-width: 600px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
  }

  header .navigation .navigation-items a {
    color: #222;
    font-size: 1.2em;
    margin: 20px;
  }

  header .navigation-items .search_container {
    border: 1px solid black;
    margin-bottom: 1.3em;
  }

  header .navigation-items .search_bar {
    border-bottom: 1px solid black;
  }

  header .navigation .navigation-items a:before {
    margin-bottom: -10px;
    background: #222;
    height: 5px;
  }

  header .navigation-items .fa-magnifying-glass {
    color: black;
  }

  .cusotmer_section {
    margin-top: 2em;
  }

  .cart {
    width: 40%;
  }

}


@media (max-width: 1000px) {
  header .navigation .navigation-items {
    margin-top: 5em;
  }

}

@media (max-width: 800px) {
  header .navigation .navigation-items {
    margin-top: 13em;
  }

}



@media (max-width: 700px) {
  header {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    transition: 0.5s ease;
  }

  header .navigation a {
    display: block;
  }

  .address {
    display: none;
  }

  header hr {
    display: none;
  }

  section {
    padding: 100px 20px;
  }

  header .navigation {
    display: none;
  }

  header .navigation_active {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.5);
  }

  header .navigation .navigation-items a {
    color: #222;
    font-size: 1.2em;
    margin: 20px;
  }

  header .navigation .navigation-items {
    margin-top: 0em;
  }

  header .navigation-items .search_container {
    border: 1px solid black;
    margin-bottom: 1.3em;
  }

  header .navigation-items .search_bar {
    border-bottom: 1px solid black;
  }

  header .navigation .navigation-items a:before {
    margin-bottom: -10px;
    background: #222;
    height: 5px;
  }

  header .navigation-items .fa-magnifying-glass {
    color: black;
  }

  header .navigation .navigation-items {
    background: #ffff;
    width: 90%;
    max-width: 600px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
  }

  .second_nav {
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-btn {
    display: block;
    color: #ffff;
    cursor: pointer;
    z-index: 888;
  }

  .home .content h1 {
    font-size: 2em;
  }

  .home .content h1 span {
    font-size: 1em;
    margin-top: -10px;
  }

  .search_container {
    margin: 0;
    display: flex;
  }

  .search_container  {
    padding: 0.6em;
  }

  .header_bar {
    padding: 0 !important;
  }

  .cusotmer_section {
    margin-top: 2em;
    display: flex;
    gap: 3em;
  }

  .cusotmer_section .wish_count {
    top: -23px;
    left: 10em;
  }

  .cart {
    width: 70%;
  }
}
