.footer {
  background-image: url("../../images//footer.jpg");
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5em 14em;
}

.footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.footer_banner {
  position: relative;
  background: #39a935;
  padding: 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 2;
  border-radius: 5px;
  margin-bottom: 6em;
}

.footer_social {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 40%;
  gap: 3em;
}

.footer_social p {
  font-size: 1.2em;
  font-weight: 600;
}

.footer_social_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  font-size: 1.3em;
}

.footer_social_icons {
  border: 1px solid white;
  padding: 0.5em 0.7em;
  border-radius: 50%;
}

.facebook {
  padding: 0.5em 0.89em;
}
.footer_social_icons:hover {
  color: #39a935;
  background: white;
}

.footer_hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20em;
  z-index: 2;
  position: relative;
  margin: 1em 5em;
}

.footer_brand {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.footer_brand img {
  width: 100px;
  margin-bottom: 1em;
}

.footer_brand h3 {
  font-size: 1.6em;
}

.footer_brand .about {
  color: gray;
  line-height: 1.4em;
  margin-bottom: 1em;
}

.footer_brand .footer_contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.footer_brand .footer_contact i {
  color: #39a935;
}

.quick_links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;
  color: white;
}

.quick_links h3 {
  font-size: 1.6em;
  margin-bottom: 1em;
}

.quick_links .quick_links_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
}

.quick_links .quick_links_section a {
  color: white;
  text-decoration: none;
  font-size: 1.1em;
}

.quick_links .quick_links_section a:hover {
  color: #39a935;
}

.copyright {
  color: white;
  z-index: 2;
  position: relative;
  margin: 1em 0;
  text-align: center;
}

.quote_container .remarks,
.contact_details {
  margin-top: 1em;
}

@media (max-width: 1450px) {
  .footer {
    padding: 5em 10em;
  }
}

@media (max-width: 1290px) {
  .footer {
    padding: 5em 7em;
  }
}

@media (max-width: 1290px) {
  .footer {
    padding: 5em;
  }

  .footer_social {
    gap: 1em;
  }
  
  .footer_hero {
    gap: 12em;
    margin: 1em 3em;

  }
}

@media (max-width: 950px) {
  .footer_banner h1 {
    font-size: 1.6em;
  }

  .footer_social_icons i {
    font-size: 20px;
  }

  .footer_banner {
    justify-content: flex-start;
    gap: 1em;
  }

  .footer_banner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

  }

  .footer_hero {
    gap: 5em;
    margin: 1em 2em;

  }

}


@media (max-width: 700px) {
  .footer {
    padding: 5em 1em;
  }
  .footer_banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer_banner h1 {
    font-size: 1.3em;
  }
  .footer_social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2em;
    gap: 1em;
  }
  .footer_social {
    width: 100%;
  }

  .footer_hero {
    flex-direction: column;
    gap: 5em;
  }
  .quick_links {
    justify-content: center;
    align-items: center;
  }
  .quick_links .quick_links_section {
    align-items: center;
  }
}
