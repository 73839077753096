.categories {
  margin: 26px 0;
}

.categories h1 {
  text-align: center;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 2em 5em;
  margin: 2em 5em;
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-item {
  flex: 0 0 25%;
  text-align: center;
}

.carousel-image {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border: 4px solid rgb(49, 199, 49);
  border-radius: 50%;
  cursor: pointer;
}

.carousel-item h2 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.nav_btn {
  position: absolute;
  background: none;
  border: none;
  font-size: 50px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid;
  border-radius: 20%;
  padding: 5px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

/* services_section */

.services_section {
  padding: 2em 10em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.services_section .services {
  color: #39a935;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 1.2em;
}

.services_section h1 {
  font-size: 2.6em;
}

.cards_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
  gap: 2em;
}

.cards_container .card_items {
  background: #f7f6ee;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.7em;
  border: 2px solid transparent;

}

.cards_container .card_items:hover {
  border: 2px solid green;
}

.card_items .icon_container {
  background: #39a935;
  width: 80px;
  height: 80px;
  padding: 1.2em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: white;
}

.card_items h3 {
  font-size: 1.6em;
}

.card_items p {
  font-size: 1em;
}

.card_items img {
  max-width: 350px;
  border-radius: 10px;
}

@media (max-width: 1520px) {
  .services_section {
    padding: 2em 5em;
  }
  .cards_container .card_items {
    max-width: 400px;
  }
  .card_items img {
    max-width: 250px;
  }
}

@media (max-width: 1520px) {
  .services_section {
    padding: 2em;
  }
  .cards_container {
    gap: 1em;
  }

  .cards_container .card_items {
  width: 370px;
  }

}

@media (max-width: 1194px) {
  .cards_container {
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    align-items: center;
  }
  .cards_container .card_items {
    max-width: 400px;
  }


}

@media (max-width: 700px) {
  .carousel-container {
    padding: 2em 1em;
    margin: 2em 1em;
  }

  .carousel-image {
    width: 50px;
    height: 50px;
  }
  .carousel-item h2 {
    font-size: 10px;
  }

  .services_section .services, .services_section h1  {
    margin-left: 1em;
  }

  .nav_btn {
    font-size: 30px;
  }

  .services_section {
    padding: 2em 0em;
  }

  .services_section h1 {
    font-size: 1.6em;
  }

  .cards_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .card_items img {
    width: 300px;
  }

  .cards_container .card_items {
    justify-content: center;
    align-items: center;
  }

  .cards_container .card_items p {
    text-align: center;
  }

  .home .content h1 {
    line-height: 45px;
  }
}
