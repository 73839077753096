 .about_us {
    background-image: url(../../images/test2.jpg) !important;
    background-position: center;
    height: 500px;
}

  .about_us .shop_nav {
    /* background-color: #0fa80f;  */
    
  }

  
.about_us_section {
    padding: 1em 12em;
    margin: 1em 0;

}

  .about_us_heading {
    text-align: center;
    margin: 2em 0;
    font-size: 2em;
    text-decoration: underline;
  }

  .cutomer_counts {
    background: rgb(15, 168, 15);
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5em;
  }

  .customer_counts_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .about_contact {
    display: flex;
    padding: 0 10em;
    margin: -2em 0;
  }

  .about_contact_right {
    background: #f6f6ee;
    padding: 7em 3em;
  }

  .about_heading {
    font-size: 2.6em;
    font-weight: 700;
  }

  .about_mission_para {
    color: gray;
    margin-top: 1em;
  }

  @media (max-width: 700px) {
    
    .about_us_section {
        padding: 1em 2em;
    }

    .cutomer_counts {
        flex-direction: column;
        gap: 2em;
    }

    .about_contact { 
        margin: 1em;
        padding: 0;
        flex-direction: column;
    }

    .about_contact  .contact_img {
        width: 100%;
    }
  }