.searchBg {
  height: 350px;
}

.searchBar {
  width: 100%;
  padding: 5rem 12rem;
}

.searchBar_input {
  width: 100%;
  font-size: 1.7rem;
  border: none;
  padding: 15px;
  border: 3px solid transparent;
  border-radius: 20px;
}

.searchBar_input:focus {
  outline: none;
  border: 3px solid rgb(51, 192, 51);
}

.searchResults {
  padding: 1rem 2rem;
}
.foundProducts {
  margin: 1rem 2rem;
  font-size: 1.2rem;
}

@media (max-width: 1000px) {
  .searchBar {
    width: 100%;
    padding: 5rem;
  }
}

@media (max-width: 500px) {
  .searchBar {
    width: 100%;
    padding: 2rem;
    margin-top: 2rem;
  }
}

/*  2nd*/

.display {
  padding: 1rem 5rem;
}

.product_img {
  width: 150px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 1rem;
}

/* 3rd */
.display {
  padding: 2rem;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin: 20px 0;
  overflow-x: auto;
  /* display: block;  */
}

.product-table thead {
  display: table-header-group;
}

.product-table tbody {
  display: table-row-group;
}

.product-table th,
.product-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.product-table th {
  font-weight: bold;
}

.product_img {
  border-radius: 5px;
  width: 150px;
  height: 150px;
}

.display h3 {
  text-align: center;
  padding: 12rem 0;
}
/* Responsive Design */

@media (max-width: 840px) {
  .display {
    padding: 1rem;
  }
  .product-table {
    margin: 20px 0;
    padding: 0;
  }

  .product-table {
    display: block;
  }

  .display h3 {
    text-align: center;
    padding: 2rem 0;
  }
}
