.shop_page {
  background-image: url(../../images/test.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 500px;
}

.shop_nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2em 12em;
}

.shop_brand {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 2em;
}

.shop_navigation-items {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1em;
  padding-bottom: 5px;
}

.shop_navigation-items a {
  text-decoration: none;
  list-style: none;
  color: white;
  font-size: 1.1em;
  font-weight: 600;
  border-bottom: 2px solid transparent;
}

.shop_navigation-items a:hover {
  border-bottom: 2px solid;
}

.filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3em;
  padding: 5px;
  font-size: 20px;
  border: 1px solid rgb(180, 171, 171);
  border-radius: 10px;
  display: none;
}

.filters .fa-filter {
  border: none;
  margin: 0;
}

.shop_navigation-items .customer_section {
  display: flex;
  position: relative;
}

.shop_navigation-items .customer_section .cart_count {
  position: absolute;
  top: -20px;
  left: 17px;
  border: 1px solid gray;
  background: #ffffff;
  border-radius: 50%;
  padding: 3px 5px;
  font-size: 11px;
  color: black;
}

.shop_navigation-items .customer_section .wish_count {
  position: absolute;
  top: -19px;
  left: 55px;
  border: 1px solid gray;
  background: #ffffff;
  border-radius: 50%;
  padding: 3px 5px;
  font-size: 11px;
  color: black;
}

/* products  */
.products_container {
  padding: 4em 3em 4em 7em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3em;
  position: relative;
}

.products_left {
  width: 25%;
  /* position: sticky; */
  top: 20px;
}

.products_container .filter_type {
  font-weight: 700;
  margin-top: 2em;
}

.products_right {
  width: 80%;
}

.products_right .products_right_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products_right_top .display_style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.select_options {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 1em;
  color: green;
}

.select_options:focus {
  outline: none;
}

.display_style i {
  cursor: pointer;
  border: 2px solid rgb(208, 200, 200);
  padding: 5px;
  margin-left: 1em;
  border-radius: 6px;
}

.display_style .active {
  border: 2px solid black;
}

.products_right_middle {
  margin-top: 2.6em;
}

.products_right_middle p {
  font-size: 1.3em;
}

.products_right_container {
  margin-top: 2.6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 4em;
}

.single_product {
  border: 1px solid rgb(167, 161, 161);
  border-radius: 5px;
  padding: 1.5em;
  cursor: pointer;
  height: 550px;
  width: 300px;

}

.single_product:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.single_product img {
  width: 250px;
  border-radius: 5px;
}

.product_image:hover {
  transform: scale(1.1);
  transition: 0.7s ease-in-out;
}

.single_product h1 {
  font-size: 1.3em;
  margin: 8px 0;
}

.single_product p {
  margin-bottom: 8px;
}

.product_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product_options {
  margin-top: 10px;
}

.product_options .cart_btn {
  background: #44b140;
  color: white;
  font-size: 0.9em;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid transparent;
}

.product_options .cart_btn:hover {
  background: white;
  color: #44b140;
  border: 1px solid gray;
}

.product_options .buy {
  background: #3c89bd;
  color: white;
  font-size: 0.9em;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid transparent;
}

.product_options .buy:hover {
  background: white;
  color: #3c89bd;
  border: 1px solid gray;
}
.select_categories {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em 0;
  border-bottom: 1px solid gray;
  position: sticky;
}

.select_categories label {
  font-size: 1.2em;
  cursor: pointer;
}

.fa-cart-shopping {
  font-size: 1.6em;
}

.fa-cart-shopping:hover {
  transform: scale(1.3);
  transition: 0.7s;
}

.products_container .fa-xmark {
  display: none;
}

.above_price {
  margin: 1em 0;
}

.pagination {
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.pagination-btn {
  padding: 10px;
  font-size: 1.3rem;
  border-radius: 10px;
}

.pagination .active {
background: rgb(72, 193, 72);
color: white;
}

@media (max-width: 1575px) {
  .products_container {
    gap: 1em;
  }

  .products_right_container {
    gap: 3em;
  }
}

@media (max-width: 1500px) {
  .products_right_container {
    gap: 1em;
  }
}

@media (max-width: 1583px) {
  .shop_nav {
    padding: 25px 50px;
  }
}

@media (max-width: 1417px) {
  .products_container {
    padding: 4em 3em 4em 3em;
  }
}

@media (max-width: 1339px) {
  .single_product img {
    width: 200px;
  }

  .single_product {
    height: 480px;
  }
}

@media (max-width: 1282px) {
  .menu-btn {
    display: block;
  }

  .shop_page .navigation {
    display: none;
  }

  .search_container .search_bar {
    color: black;
  }
  .search_bar::placeholder {
    color: black;
  }

  .menu-btn {
    display: block;
    color: #ffff;
    cursor: pointer;
    z-index: 888;
  }

  .shop_nav .navigation_active {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 99;
  }

  .shop_page .shop_navigation-items {
    background: #ffff;
    width: 90%;
    max-width: 600px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    gap: 2.2em;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
    height: 63vh;
  }

  .shop_nav .shop_navigation-items a {
    color: black;
  }

  .shop_navigation-items .search_container {
    border: 1px solid black;
  }

  .shop_navigation-items .search_bar {
    border-bottom: 1px solid black;
  }

  .shop_nav .cusotmer_section {
    color: black;
  }

  .shop_navigation-items .fa-magnifying-glass {
    color: black;
  }

  .shop_page .navigation-items .fa-magnifying-glass {
    color: black;
  }

  .cusotmer_section {
    margin-top: 2em;
  }
}

@media (max-width: 1236px) {
  .single_product {
    height: 450px;
  }

  .single_product img {
    width: 200px;
  }

  .single_product h1 {
    font-size: 1em;
  }

  .product_options .cart_btn {
    padding: 7px 10px;
    font-size: 0.7em;
  }

  .product_options .buy {
    padding: 7px 10px;
    font-size: 0.7em;
  }
}

@media (max-width: 1104px) {
  .single_product img {
    width: 190px;
  }

  .single_product h1 {
    font-size: 1em;
    margin: 8px 0;
  }

  .product_options .cart_btn,
  .product_options .buy {
    padding: 10px 15px;
    font-size: 0.7em;
  }

  .products_container {
    padding: 4em 3em;
  }

  .single_product {
    width: 250px;
  }
}

@media (max-width: 1000px) {
  .shop_page .navigation .navigation-items {
    margin-top: 5em;
  }
}

@media (max-width: 800px) {
  .shop_page .navigation .navigation-items {
    margin-top: 13em;
  }
}

@media (max-width: 700px) {
  .shop_page {
    height: 350px;
  }

  .shop_nav {
    padding: 1em;
  }

  .shop_nav .shop_brand {
    font-size: 1.5em;
  }

  .shop_nav .navigation {
    display: none;
  }

  .shop_nav .navigation_active {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 99;
  }

  .shop_page .shop_navigation-items {
    background: #ffff;
    width: 90%;
    max-width: 600px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    gap: 2.2em;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
    height: 63vh;
  }

  .shop_nav .shop_navigation-items a {
    color: black;
  }

  .shop_navigation-items .search_container {
    border: 1px solid black;
  }

  .shop_navigation-items .search_bar {
    border-bottom: 1px solid black;
  }

  .shop_navigation-items .fa-magnifying-glass {
    color: black;
  }

  .products_container {
    padding: 4em 0.6em;
  }

  .products_container .products_left {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    color: black;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.4s ease;
    z-index: 10;
  }

  .products_container .mobile_filter_slider {
    left: 0;
  }

  .products_container h2 {
    display: inline;
    margin-right: 9em;
  }

  .products_container .fa-xmark {
    font-size: 2em;
    z-index: 999;
    display: inline;
  }

  .filters {
    display: block;
  }
  .products_right {
    width: 100%;
  }

  .products_right .products_right_top {
    flex-direction: column;
  }

  .products_right_container {
    gap: 1.3em;
  }

  .single_product {
    border: 1px solid rgb(167, 161, 161);
    border-radius: 5px;
    padding: 0.3em;
    cursor: pointer;
    width: 180px;
    height: 370px;
  }

  .single_product img {
    width: 165px;
  }

  .single_product h1 {
    font-size: 0.9em;
    margin: 8px 0;
  }

  .single_product p {
    font-size: 0.7em;
  }

  .product_options .cart_btn {
    font-size: 0.6em;
    font-weight: 600;
    padding: 10px;
  }

  .product_options .buy {
    font-size: 0.6em;
    font-weight: 600;
    padding: 10px;
    margin-left: 5px;
  }

  .products_right_top .display_style {
    margin-top: 1em;
  }

  .shop_nav .customer_section {
    margin-top: 2em;
    position: relative;
    display: flex;
  }

  .shop_nav .customer_section .wish_count {
    top: -19px;
    left: 5em;
  }
}

@media (max-width: 440px) {
  .products_container .products_left {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    color: black;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.4s ease;
    z-index: 10;
  }

  .products_container .mobile_filter_slider {
    left: 0;
  }
}
